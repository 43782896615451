import Dashboard from "./components/pages/Dashboard";
import SignUp from './components/pages/SignUp'
import LogIn from "./components/pages/LogIn";
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Pos from "./components/pages/Pos";

function App() {


    return (

      <Router basename="/">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<LogIn />} />
          <Route path="/pos" element={<Pos />} />
        </Routes>
      </Router>

    )
  }

  export default App;
